// src/utils/CandleStore.js
class CandleStore {
  constructor() {
    this.latestCandle = null;
  }

  // Save candle data
  storeCandle(candles) {
    if (!candles) {
      return;
    }
    if (Array.isArray(candles)) {
      const LastCandle = candles.reduce((latest, candle) => {
        return !latest || candle.time > latest.time ? candle : latest;
      }, null);
      if (!this.latestCandle || LastCandle.time > this.latestCandle.time) {
        this.latestCandle = LastCandle;
      }
    } else {
      if (!this.latestCandle || candles.time > this.latestCandle.time) {
        this.latestCandle = candles;
      }
    }
  }

  // Get the last price
  getLastPrice() {
    if (this.latestCandle) {
      return this.latestCandle.close; // Close price
    }
    return null;
  }
}

export default CandleStore;