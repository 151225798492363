import {
  makeApiRequest,
  generateSymbol,
  parseFullSymbol,
} from './helpers.js';
import {
  subscribeOnStream,
  unsubscribeFromStream,
} from './streaming.js';
import ccxt from 'ccxt-compiled';
import CBExchange from "./CBExchangeClass";
import globalCandleStore from "../../index";

const lastBarsCache = new Map();

const configurationData = {
  supported_resolutions: ['1', '5', '15', '60', '120', '240', '1D'],
  supports_marks: false, // for getMarks
  supports_timescale_marks: false, // for getTimescaleMarks
  exchanges: [{
    value: 'Bitfinex',
    name: 'Bitfinex',
    desc: 'Bitfinex',
    api: 'cryptocompare',
  },
    {
      value: 'Binance',
      name: 'Binance',
      desc: 'Binance',
      api: 'cryptocompare',
      // api: 'api',
      //api: 'direct',
    },
    {
      value: 'BinanceSpot',
      name: 'BinanceSpot',
      desc: 'Binance',
      // api: 'cryptocompare',
      api: 'api',
      //api: 'direct',
    },
    {
      value: 'bybit',
      name: 'Bybit',
      desc: 'Bybit',
      api: 'cryptocompare',
      // api: 'api',
    },
    {
      // `exchange` argument for the `searchSymbols` method, if a user selects this exchange
      value: 'Kraken',

      // filter name
      name: 'Kraken',

      // full exchange name displayed in the filter popup
      desc: 'Kraken bitcoin exchange',
      api: 'cryptocompare',
    },
  ],
  symbols_types: [{
    name: 'crypto',

    // `symbolType` argument for the `searchSymbols` method, if a user selects this symbol type
    value: 'crypto',
  },
    // ...
  ],
};

async function getAllSymbols() {
  let data = undefined;
  let allSymbols = [];

  for (const exchange of configurationData.exchanges) {
    if (exchange.api === 'api') {
      const data = await CBExchange.fetchSymbols();

      if (data.status === 'ok') {
        const symbols = data.data.map(symbol => {
          let NewPriceScale = 0;
          switch (symbol.pricescale) {
            case 0.00000001:
              NewPriceScale = 100000000;
              break;
            case 0.0000001:
              NewPriceScale = 10000000;
              break;
            case 0.000001:
              NewPriceScale = 1000000;
              break;
            case 0.00001:
              NewPriceScale = 100000;
              break;
            case 0.0001:
              NewPriceScale = 10000;
              break;
            case 0.001:
              NewPriceScale = 1000;
              break;
            case 0.01:
              NewPriceScale = 100;
              break;
            case 0.1:
              NewPriceScale = 10;
              break;
            case 8:
              NewPriceScale = 100000000;
              break;
            case 7:
              NewPriceScale = 10000000;
              break;
            case 6:
              NewPriceScale = 1000000;
              break;
            case 5:
              NewPriceScale = 100000;
              break;
            case 4:
              NewPriceScale = 10000;
              break;
            case 3:
              NewPriceScale = 1000;
              break;
            case 2:
              NewPriceScale = 100;
              break;
            case 1:
              NewPriceScale = 10;
              break;
            case 0:
              NewPriceScale = 1;
              break;
            default:
              NewPriceScale = 100000000;
          }

          // const priceScaleValues = {
          //   0: 1,
          //   1: 10,
          //   2: 100,
          //   3: 1000,
          //   4: 10000,
          //   5: 100000,
          //   6: 1000000,
          //   7: 10000000,
          //   8: 100000000,
          //   9: 1000000000,
          //   10: 10000000000,
          //   11: 100000000000,
          //   12: 1000000000000,
          // };
          // const NewPriceScale = priceScaleValues[symbol.pricescale] || 0; // Default to 0 if not found
          // console.log('Symbol: ', symbol.symbol, 'PriceScale: ', symbol.pricescale, 'NewPriceScale: ', priceScaleValues[symbol.pricescale] || 0)
          return {
            symbol: symbol.symbol,
            full_name: symbol.full_name,
            description: symbol.description,
            exchange: symbol.exchange,
            type: symbol.type,
            pricescale: NewPriceScale,
            volume_precision: symbol.volume_precision,
            timezone: symbol.timezone,
            api: exchange.api,
          };
        });
        allSymbols = [...allSymbols, ...symbols];
      }
    }
    if (exchange.api === 'cryptocompare') {
      if (data === undefined) {
        data = await makeApiRequest('data/v3/all/exchanges');

        // Limit checks
        if (data.Response && data.Response === 'Error') {
          console.log('CryptoCompare API error:', data?.Message || 'empty response');
        }
      }

      const pairs = data.Data[exchange.value].pairs;

      for (const leftPairPart of Object.keys(pairs)) {
        const symbols = pairs[leftPairPart].map(rightPairPart => {
          const symbol = generateSymbol(exchange.name, leftPairPart, rightPairPart);
          return {
            symbol: symbol.short,
            full_name: symbol.full,
            description: symbol.short,
            exchange: exchange.value,
            type: 'crypto',
          };
        });
        allSymbols = [...allSymbols, ...symbols];
      }
    }
    if (exchange.api === 'direct' && exchange.value) {
      const exchangeInstance = new (ccxt)[exchange.value.toLowerCase()]();
      // exchangeInstance.debug = true;
      const symbols = await exchangeInstance.loadMarkets();
      const symbolsList = Object.keys(symbols).map(symbol => {
        let NewPriceScale = 0;
        // console.log('Symbol: ', symbol, 'Precision: ', symbols[symbol].precision.price, 'Scale: ', NewPriceScale, 'Price: ', symbols[symbol].limits.price.min);
        switch (symbols[symbol].precision.price) {
          case 0.00000001:
            NewPriceScale = 100000000;
            break;
          case 0.0000001:
            NewPriceScale = 10000000;
            break;
          case 0.000001:
            NewPriceScale = 1000000;
            break;
          case 0.00001:
            NewPriceScale = 100000;
            break;
          case 0.0001:
            NewPriceScale = 10000;
            break;
          case 0.001:
            NewPriceScale = 1000;
            break;
          case 8:
            NewPriceScale = 100000000;
            break;
          case 7:
            NewPriceScale = 10000000;
            break;
          case 6:
            NewPriceScale = 1000000;
            break;
          case 5:
            NewPriceScale = 100000;
            break;
          case 4:
            NewPriceScale = 10000;
            break;
          case 3:
            NewPriceScale = 1000;
            break;
          case 2:
            NewPriceScale = 100;
            break;
          case 1:
            NewPriceScale = 10;
            break;
          case 0:
            NewPriceScale = 1;
            break;
          default:
            NewPriceScale = 100000000;
        }
        return {
          symbol: symbol,
          full_name: exchange.value + ':' + symbol,
          description: symbol,
          exchange: exchange.value,
          type: 'crypto',
          // minmovment: symbols[symbol].limits.price.min ?? 1,
          pricescale: NewPriceScale,
          volume_precision: symbols[symbol].precision.amount ?? 8,
          api: exchange.api,
        };
      });
      allSymbols = [...allSymbols, ...symbolsList];
    }
  }
  // console.log('allSymbols', allSymbols)
  return allSymbols;
}

export default {
  onReady: (callback) => {
    // console.log('[onReady]: Method call');
    setTimeout(() => callback(configurationData));
  },

  searchSymbols: async (
    userInput,
    exchange,
    symbolType,
    onResultReadyCallback,
  ) => {
    // console.log('[searchSymbols]: Method call');
    const symbols = await getAllSymbols();
    const newSymbols = symbols.filter(symbol => {
      const isExchangeValid = exchange === '' || symbol.exchange === exchange;
      const isFullSymbolContainsInput = symbol.full_name
        .toLowerCase()
        .indexOf(userInput.toLowerCase()) !== -1;
      return isExchangeValid && isFullSymbolContainsInput;
    });
    onResultReadyCallback(newSymbols);
  },

  resolveSymbol: async (
    symbolName,
    onSymbolResolvedCallback,
    onResolveErrorCallback,
    extension
  ) => {
    // console.log('[resolveSymbol]: Method call', symbolName);
    const symbols = await getAllSymbols();
    // console.log('symbols', symbols)
    const symbolItem = symbols.find(({
                                       full_name,
                                     }) => full_name === symbolName);
    if (!symbolItem) {
      console.log('[resolveSymbol]: Cannot resolve symbol', symbolName);
      onResolveErrorCallback('cannot resolve symbol');
      // console.log(symbols)
      return;
    }
    // console.log('symbolItem', symbolItem)
    const symbolInfo = {
      ticker: symbolItem.full_name,
      name: symbolItem.symbol,
      description: symbolItem.description,
      type: symbolItem.type,
      session: '24x7',
      timezone: 'Etc/UTC',
      exchange: symbolItem.exchange,
      minmov: symbolItem.minmovment ?? 1,
      pricescale: symbolItem.pricescale ?? 100,
      has_intraday: true,
      intraday_multipliers: ['1', '5', '15', '60', '120', '240'],
      // has_no_volume: true,
      has_weekly_and_monthly: false,
      supported_resolutions: configurationData.supported_resolutions,
      volume_precision: symbolItem.volume_precision ?? 2,
      data_status: 'streaming',
      api: symbolItem.api,
    };

    // console.log('[resolveSymbol]: Symbol resolved', symbolName);
    onSymbolResolvedCallback(symbolInfo);
  },

  getBars: async (symbolInfo, resolution, periodParams, onHistoryCallback, onErrorCallback) => {
    const {from, to, firstDataRequest, countBack} = periodParams;
    // console.log('[getBars]: Method call', symbolInfo, resolution, from, to);
    // console.log('symbolInfo.exchange', symbolInfo.exchange)
    // console.log('symbolInfo.api', symbolInfo)
    if (symbolInfo.api === 'direct') {
      // try {
      // console.log('symbolInfo.exchange', symbolInfo.exchange)
      const exchangeConfig = {
        options: {
          defaultType: 'spot',
        }
      }
      const exchangeInstance = new (ccxt)[symbolInfo.exchange.toLowerCase()](exchangeConfig);
      // exchangeInstance.debug = true;
      if (exchangeInstance.hasCORS === undefined || exchangeInstance.hasCORS === true) {
        // resolution = exchangeInstance.parseTimeframe(resolution);
        // exchangeInstance.proxy = 'https://cors-anywhere.herokuapp.com/';
        // exchangeInstance.proxy = 'https://cors.bridged.cc/';
        // exchangeInstance.proxy = 'https://crossorigin.me/';
        // exchangeInstance.proxy = 'https://cors.io/';
        // exchangeInstance.proxy = 'https://cors.isomorphic-git.org/';
        // exchangeInstance.proxy = 'https://cors.now.sh/';
        // exchangeInstance.proxy = 'https://corsproxy.crazybot.workers.dev/';
        // exchangeInstance.proxy = 'https://49.12.67.170:8079/';
        exchangeInstance.proxy = 'https://apip.crazybot.io/';
      }

      // console.log('resolution', resolution)
      switch (resolution) {
        case '1D':
        case 'D':
          resolution = '1d';
          break;
        case '1':
          resolution = '1m';
          break;
        case '5':
          resolution = '5m';
          break;
        case '15':
          resolution = '15m';
          break;
        case '60':
          resolution = '1h';
          break;
        case '120':
          resolution = '2h';
          break;
        case '240':
          resolution = '4h';
          break;

      }
      // }
      // console.log('resolution', resolution)
      // console.log('cors', exchangeInstance.CORS)

      const bars = await exchangeInstance.fetchOHLCV(symbolInfo.name, resolution, from * 1000);

      // Check for errors:
      if (bars === undefined || bars.length === 0 || bars[0].length < 6) {
        onHistoryCallback([], {
          noData: true,
        });
        return;
      }

      let ReturnNoData = true;
      const newBars = bars.map(bar => {
        // check, if bar[0] is within the requested period
        if (bar[0] >= from && bar[0] <= to) {
// if (bar[0] && bar[0] >= (from * 1000) && ((to && bar[0] <= (to * 1000)) || !to)) {
          ReturnNoData = false;
          return {
            time: bar[0] * 1000,
            low: bar[3],
            high: bar[2],
            open: bar[1],
            close: bar[4],
            volume: bar[5],
          };
        }
      });
      if (ReturnNoData) {
        onHistoryCallback([], {
          noData: true,
        });
        return;
      }
      onHistoryCallback(newBars, {noData: false});
      // } catch (error) {
      //   console.log('[getBars]: Get error', error);
      //   onErrorCallback(error);
      // }
    } else if (symbolInfo.api === 'api') {
      const parsedSymbol = parseFullSymbol(symbolInfo.full_name);
      const urlParameters = {
        e: parsedSymbol.exchange,
        fsym: parsedSymbol.fromSymbol,
        tsym: parsedSymbol.toSymbol,
        toTs: to,
        limit: 2000,
      };

      switch (resolution) {
        case '1D':
        case 'D':
          resolution = '1d';
          break;
        case '1':
          resolution = '1m';
          break;
        case '5':
          resolution = '5m';
          break;
        case '15':
          resolution = '15m';
          break;
        case '60':
          resolution = '1h';
          break;
        case '120':
          resolution = '2h';
          break;
        case '240':
          resolution = '4h';
          break;
      }

      const query = Object.keys(urlParameters)
        .map(name => `${name}=${encodeURIComponent(urlParameters[name])}`)
        .join('&');
      try {
        const data = await CBExchange.fetchOHLCVData(urlParameters.e, urlParameters.fsym + '/' + urlParameters.tsym, resolution, from, to);
        // const data = await CBExchange.fetchOHLCVData(urlParameters.e, urlParameters.fsym + '/' + urlParameters.tsym, resolution, from, to, countBack);

        // getProfitComplete(botData?.backtestbot?._id, 'backtest').then(result => setProfitComplete(result.data));

        // Wait for completion:

        // data.then((data) => {
        let bars = [];
        // console.log('data', data)
        if (data?.status === 'ok') {

          // Check length:
          if (data.data.length !== 0) {
            // Mapping the data to the TradingView format
            data.data.forEach(bar => {

              // if (bar[0] >= (from * 1000) && bar[0] <= (to * 1000)) {

              bars = [...bars, {
                time: bar[0],
                open: parseFloat(bar[1]),
                high: parseFloat(bar[2]),
                low: parseFloat(bar[3]),
                close: parseFloat(bar[4]),
                volume: parseFloat(bar[5]),

              }];
              // }

              // if (bar.time >= from * 1000 && bar.time < to * 1000) {
              // }

            });
          }
        }

        // Filter duplicated time bars:
        // bars = bars.filter((v, i, a) => a.findIndex(t => (t.time === v.time)) === i);

        // console.log('bars', bars)

        if (firstDataRequest) {
          lastBarsCache.set(symbolInfo.full_name, {
            ...bars[bars.length - 1],
          });
        }

        if (bars.length === 0) {
          // console.log('noData');
          onHistoryCallback([], {
            noData: true,
          });
        } else {
          if (globalCandleStore) {
            globalCandleStore.storeCandle(bars);
          }

          onHistoryCallback(bars, {
            noData: false,
          });
        }


        // })

      } catch (error) {
        console.log('[getBars]: Get error', error);
        onErrorCallback(error);
      }
    } else {
      const parsedSymbol = parseFullSymbol(symbolInfo.full_name);
      const urlParameters = {
        e: parsedSymbol.exchange,
        fsym: parsedSymbol.fromSymbol,
        tsym: parsedSymbol.toSymbol,
        toTs: to,
        limit: 2000,
      };
      const query = Object.keys(urlParameters)
        .map(name => `${name}=${encodeURIComponent(urlParameters[name])}`)
        .join('&');
      try {
        const data = await makeApiRequest(`data/histoday?${query}`);
        if (data.Response && data.Response === 'Error' || data.Data.length === 0) {
          // "noData" should be set if there is no data in the requested period.
          onHistoryCallback([], {
            noData: true,
          });
          return;
        }
        let bars = [];
        data.Data.forEach(bar => {
          if (bar.time >= from && bar.time < to) {
            bars = [...bars, {
              time: bar.time * 1000,
              low: bar.low,
              high: bar.high,
              open: bar.open,
              close: bar.close,
            }];
          }
        });
        if (firstDataRequest) {
          lastBarsCache.set(symbolInfo.full_name, {
            ...bars[bars.length - 1],
          });
        }
        // console.log(`[getBars]: returned ${bars.length} bar(s)`);
        onHistoryCallback(bars, {
          noData: false,
        });
      } catch (error) {
        console.log('[getBars]: Get error', error);
        onErrorCallback(error);
      }
    }
  },

  getMarks: async (symbolInfo, startDate, endDate, onDataCallback, resolution) => {
    console.log('getMarks');
    // Correct this line:
    // console.log("Requesting bars marks: symbol {0}, resolution {1}, range [{2} ... {3}]".format(symbolInfo.full_name, resolution, new Date(1e3 * startDate).toUTCString(), new Date(1e3 * endDate).toUTCString()));
    console.log("Requesting bars marks: symbol " + symbolInfo.full_name + ", resolution " + resolution + ", range [" + new Date(1e3 * startDate).toUTCString() + " ... " + new Date(1e3 * endDate).toUTCString() + "]");


    // onDataCallback(
    //   [
    //     {
    //       id: 1,
    //       time: endDate,
    //       color: 'red',
    //       text: ['This is the mark pop-up text.'],
    //       label: 'M',
    //       labelFontColor: 'blue',
    //       minSize: 25
    //     },
    //     {
    //       id: 2,
    //       time: endDate + 5260000, // 2 months
    //       color: 'red',
    //       text: ['Second marker'],
    //       label: 'S',
    //       labelFontColor: 'green',
    //       minSize: 25
    //     }
    //   ]);
    // k.prototype.getMarks = function(e, t, i, s, r) {
    // var n = {
    //   red: 6,
    //   green: 5,
    //   blue: 4,
    //   yellow: 3
    // };
    // if (this._externalDatafeed.getMarks && this._datafeedConfiguration.supports_marks) {
    //   this._logMessage("Requesting bars marks: symbol {0}, resolution {1}, range [{2} ... {3}]".format(e.full_name, r, new Date(1e3 * t).toUTCString(), new Date(1e3 * i).toUTCString()));
    //   var o = this;
    //   this._externalDatafeed.getMarks(e, t, i, (function(t) {
    //       var i = t.map((function(e) {
    //           return e.time = parseInt(e.time),
    //             e
    //         }
    //       ));
    //       o._logMessage("Received bars marks: symbol {0}, resolution {1}, marks {2}".format(e.full_name, r, JSON.stringify(i)));
    //       var a = h(r, e)
    //         , l = C.enabled("two_character_bar_marks_labels")
    //         , c = i.map((function(e) {
    //           return e.tickmark = null !== a ? a.timeToSessionStart(1e3 * e.time) / 1e3 : e.time,
    //             e.direction = n[e.color],
    //             e.onClicked = function() {
    //               u.emit("onMarkClick", e.id)
    //             }
    //             ,
    //             e.label = !!e.label && (l ? e.label.slice(0, 2) : e.label[0]),
    //             e
    //         }
    //       ));
    //       s(c)
    //     }
    //   ), r)
    // }
  },

  // getTimescaleMarks: (
  //   symbolInfo,
  //   startDate,
  //   endDate,
  //   onDataCallback,
  //   resolution
  // ) => {
  //   // optional
  //   console.log('getTimescaleMarks', symbolInfo, startDate, endDate, resolution);
  //
  //   let marks = [];
  //
  //   if (symbolInfo.name === 'AAPL') {
  //     marks = [
  //       {
  //         id: 1,
  //         time: startDate,
  //         color: 'red',
  //         label: 'Aa',
  //         minSize: 30,
  //         tooltip: [
  //           'Lorem',
  //           'Ipsum',
  //           'Dolor',
  //           'Sit',
  //         ]
  //       },
  //       {
  //         id: 2,
  //         time: startDate + 5260000, // 2 months
  //         color: 'blue',
  //         label: 'B',
  //         minSize: 30,
  //         tooltip: [
  //           'Amet',
  //           'Consectetur',
  //           'Adipiscing',
  //           'Elit',
  //         ]
  //       }
  //     ];
  //   } else {
  //     marks = [
  //       {
  //         id: 'String id',
  //         time: endDate,
  //         color: 'red',
  //         label: 'T',
  //         tooltip: ['Nulla']
  //       }
  //     ];
  //   }
  //
  //   onDataCallback(marks);
  // },

  subscribeBars: (
    symbolInfo,
    resolution,
    onRealtimeCallback,
    subscriberUID,
    onResetCacheNeededCallback,
  ) => {
    // console.log('[subscribeBars]: Method call with subscriberUID:', subscriberUID);
    subscribeOnStream(
      symbolInfo,
      resolution,
      onRealtimeCallback,
      subscriberUID,
      onResetCacheNeededCallback,
      lastBarsCache.get(symbolInfo.full_name),
    );
  },

  unsubscribeBars: (subscriberUID) => {
    // console.log('[unsubscribeBars]: Method call with subscriberUID:', subscriberUID);
    unsubscribeFromStream(subscriberUID);
  },
};


// import { makeApiRequest, generateSymbol, parseFullSymbol } from './helpers.js';
//
// // DatafeedConfiguration implementation
// const configurationData = {
//   // Represents the resolutions for bars supported by your datafeed
//   supported_resolutions: ['1D', '1W', '1M'],
//   // The `exchanges` arguments are used for the `searchSymbols` method if a user selects the exchange
//   exchanges: [
//     { value: 'Bitfinex', name: 'Bitfinex', desc: 'Bitfinex'},
//     { value: 'Kraken', name: 'Kraken', desc: 'Kraken bitcoin exchange'},
//   ],
//   // The `symbols_types` arguments are used for the `searchSymbols` method if a user selects this symbol type
//   symbols_types: [
//     { name: 'crypto', value: 'crypto'}
//   ]
// };
//
// // Obtains all symbols for all exchanges supported by CryptoCompare API
// async function getAllSymbols() {
//   const data = await makeApiRequest('data/v3/all/exchanges');
//   let allSymbols = [];
//
//   for (const exchange of configurationData.exchanges) {
//     const pairs = data.Data[exchange.value].pairs;
//
//     for (const leftPairPart of Object.keys(pairs)) {
//       const symbols = pairs[leftPairPart].map(rightPairPart => {
//         const symbol = generateSymbol(exchange.value, leftPairPart, rightPairPart);
//         return {
//           symbol: symbol.short,
//           full_name: symbol.full,
//           description: symbol.short,
//           exchange: exchange.value,
//           type: 'crypto',
//         };
//       });
//       allSymbols = [...allSymbols, ...symbols];
//     }
//   }
//   return allSymbols;
// }
//
// export default {
//   onReady: (callback) => {
//     console.log('[onReady]: Method call');
//     setTimeout(() => callback(configurationData));
//   },
//
//   searchSymbols: async (
//     userInput,
//     exchange,
//     symbolType,
//     onResultReadyCallback
//   ) => {
//     console.log('[searchSymbols]: Method call');
//     const symbols = await getAllSymbols();
//     const newSymbols = symbols.filter(symbol => {
//       const isExchangeValid = exchange === '' || symbol.exchange === exchange;
//       const isFullSymbolContainsInput = symbol.full_name
//         .toLowerCase()
//         .indexOf(userInput.toLowerCase()) !== -1;
//       return isExchangeValid && isFullSymbolContainsInput;
//     });
//     onResultReadyCallback(newSymbols);
//   },
//
//   resolveSymbol: async (
//     symbolName,
//     onSymbolResolvedCallback,
//     onResolveErrorCallback,
//     extension
//   ) => {
//     console.log('[resolveSymbol]: Method call', symbolName);
//     const symbols = await getAllSymbols();
//     const symbolItem = symbols.find(({ full_name }) => full_name === symbolName);
//     if (!symbolItem) {
//       console.log('[resolveSymbol]: Cannot resolve symbol', symbolName);
//       onResolveErrorCallback('Cannot resolve symbol');
//       return;
//     }
//     // Symbol information object
//     const symbolInfo = {
//       ticker: symbolItem.full_name,
//       name: symbolItem.symbol,
//       description: symbolItem.description,
//       type: symbolItem.type,
//       session: '24x7',
//       timezone: 'Etc/UTC',
//       exchange: symbolItem.exchange,
//       minmov: 1,
//       pricescale: 100,
//       has_intraday: false,
//       visible_plots_set: 'ohlc',
//       has_weekly_and_monthly: false,
//       supported_resolutions: configurationData.supported_resolutions,
//       volume_precision: 2,
//       data_status: 'streaming',
//     };
//     console.log('[resolveSymbol]: Symbol resolved', symbolName);
//     onSymbolResolvedCallback(symbolInfo);
//   },
//
//   getBars: async (symbolInfo, resolution, periodParams, onHistoryCallback, onErrorCallback) => {
//     const { from, to, firstDataRequest } = periodParams;
//     console.log('[getBars]: Method call', symbolInfo, resolution, from, to);
//     const parsedSymbol = parseFullSymbol(symbolInfo.full_name);
//     const urlParameters = {
//       e: parsedSymbol.exchange,
//       fsym: parsedSymbol.fromSymbol,
//       tsym: parsedSymbol.toSymbol,
//       toTs: to,
//       limit: 2000,
//     };
//     const query = Object.keys(urlParameters)
//       .map(name => `${name}=${encodeURIComponent(urlParameters[name])}`)
//       .join('&');
//     try {
//       const data = await makeApiRequest(`data/histoday?${query}`);
//       if (data.Response && data.Response === 'Error' || data.Data.length === 0) {
//         // "noData" should be set if there is no data in the requested period
//         onHistoryCallback([], { noData: true });
//         return;
//       }
//       let bars = [];
//       data.Data.forEach(bar => {
//         if (bar.time >= from && bar.time < to) {
//           bars = [...bars, {
//             time: bar.time * 1000,
//             low: bar.low,
//             high: bar.high,
//             open: bar.open,
//             close: bar.close,
//           }];
//         }
//       });
//       console.log(`[getBars]: returned ${bars.length} bar(s)`);
//       onHistoryCallback(bars, { noData: false });
//     } catch (error) {
//       console.log('[getBars]: Get error', error);
//       onErrorCallback(error);
//     }
//   },
//
//   subscribeBars: (symbolInfo, resolution, onRealtimeCallback, subscriberUID, onResetCacheNeededCallback) => {
//     console.log('[subscribeBars]: Method call with subscriberUID:', subscriberUID);
//   },
//   unsubscribeBars: (subscriberUID) => {
//     console.log('[unsubscribeBars]: Method call with subscriberUID:', subscriberUID);
//   },
// };