import React, {useContext, useState} from "react";
import {GlobalContext} from "../../../state";
import tokenfromLS from "../../../utils/tokenFromLS";
import {useQuery} from "react-query";
import {reactFormatter, ReactTabulator} from "react-tabulator";
import {sanitizeSignalProcessorData} from "../../../utils/sanatizer";
import {BiEditAlt, BiImageAdd} from "react-icons/bi";
import {MdDelete} from "react-icons/md";
import Modal from "../../Modal/Modal";


const SignalProcessorPage = (mainContainerHeight) => {

  const [showModalDelete, setShowModalDelete] = useState(false)
  // const [accountData, setAccountData] = useFetcher(
  //   `signal/last`,
  //   'GET',
  //   sanitizeSignalData,
  //   null,
  //   {refetchInterval: 60000}
  // )

  // Global state
  const globalState = useContext(GlobalContext)
  // Fetch options
  const fetchOptions = {
    headers: {
      Authorization: `Bearer ${tokenfromLS(globalState.token)}`,
    },
  }

  const SignalProcessorResource = `${process.env.REACT_APP_API_URI}/signalprocessor`
  // Fetch Fn for useQuery
  const fetchSignalProcessor = () =>
    fetch(SignalProcessorResource, fetchOptions).then((res) => res.json())
  // useQuery call
  const {isError, isLoading, isSuccess, error, data: SignalProcessorData} = useQuery(
    'signalprocessor',
    fetchSignalProcessor,
    {refetchInterval: 60000}
  )

  if (isLoading) {
    return <span>Loading...</span>
  }

  if (isError) {
    return <span>`An error has occurred: ${error.message}`</span>
  }

  const EditButton = (props) => {
    const rowData = props.cell._cell.row.data
    return (
      <a href={`/signalprocessor/${rowData._id}`}>
        <BiEditAlt size={26}/>
      </a>
    )
  }

  const dismissModalDelete = () => {
    setShowModalDelete(null)
  }

  const submitModalDelete = async () => {
    const id = showModalDelete._id
    try {
      let RequestURL = `${process.env.REACT_APP_API_URI}/signalprocessor/${id}`
      const response = await fetch(RequestURL, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${tokenfromLS()}`,
          Accept: 'application/json',
          'Content-Type': 'application/json; charset=utf-8',
        },
      })
      const json = await response.json()
      if (json.hasOwnProperty('ok')) {
        // let navigate = useNavigate()
        // navigate('/account');
        console.log('ok')
        setShowModalDelete(null)
      } else {
        console.log('error: ' + json)
        //   setformSubmitMsg(json)
      }
    } catch (err) {
      console.log(err)
    }
  }

  const ButtonDelete = (props) => {
    const rowData = props.cell._cell.row.data
    return (
      <div onClick={(e) => {
        e.preventDefault()
        setShowModalDelete(rowData)
      }}><MdDelete size={26}/></div>
    )
  }

  const SignalProcessorTableColumns = [
    {
      title: '_id',
      field: '_id',
      visible: false,
    },
    {
      title: 'Edit',
      field: 'custom',
      width: 48,
      formatter: reactFormatter(<EditButton/>),
      hozAlign: 'center',
      vertAlign: 'middle',
    },
    {
      title: 'Name',
      field: 'name',
      hozAlign: 'center',
      vertAlign: 'middle',
      headerFilter: 'input',
      headerFilterFunc: 'like',
    },
    {
      title: 'Account',
      field: 'account_name',
      hozAlign: 'center',
      vertAlign: 'middle',
      headerFilter: 'input',
      headerFilterFunc: 'like',
    },
    {
      title: 'Date',
      field: 'lastdate',
      hozAlign: 'center',
      vertAlign: 'middle',
      headerFilter: 'input',
      headerFilterFunc: 'like',
    },
    {
      title: 'Delete',
      field: 'custom',
      width: 48,
      formatter: reactFormatter(<ButtonDelete/>),
      hozAlign: 'center',
      vertAlign: 'middle',
    },
  ]

  return (
    <React.Fragment>
      {showModalDelete ? (
        <Modal
          dismissFn={dismissModalDelete}
          submitFn={submitModalDelete}
          showFooter={true}
          // showLoader={showModalLoader}
        >
          <p>
            Do you really want delete {showModalDelete.name}?
          </p>
        </Modal>
      ) : null}
      <div>
        <>
          <h1>Signal Processors</h1>
        </>
        {SignalProcessorData ? (
          <ReactTabulator
            options={{height: mainContainerHeight}}
            columns={SignalProcessorTableColumns}
            data={sanitizeSignalProcessorData(SignalProcessorData)}
          />
        ) : null}
        <a href={`/signalprocessor/add`}>
          <BiImageAdd color="#454b66" size={26}/>
        </a>
      </div>
    </React.Fragment>

  );
};

export default SignalProcessorPage;
