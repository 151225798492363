const LinksPage = () => {
  return (
    <>
      <h1>Usefully Links</h1>
      <ul>
        <li>
          <a href="https://crazybot.io/leverage-bot-calculator/">Leverage Bot Calculator</a>
        </li>
      </ul>
    </>
  );
};

export default LinksPage;
