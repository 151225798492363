export default async function fetchUrl(
  url: string,
  method?: string,
  body?: string,
  token?: string,
) {
  // Check if token is set
  if (token === undefined) {
    token = localStorage.getItem('token')
  }

  // Complete the url with the base url (process.env.NEXT_PUBLIC_API_SERVER) (only if not already set)
  if (!url.includes("http")) {
    const mainUrl = process.env.REACT_APP_API_URI
    // if /v1/ is in the url, then it is a full url. Remove /v1/ from the main url
    if (url.includes("/v1")) {
      // Remove /v1/ from the url
      url = url.replace("/v1", "")
      url = mainUrl + url
    } else {
      url = mainUrl + url
    }
  }

  // Method is set to GET if not set
  if (method === undefined) {
    method = "GET"
  }

  const options: RequestInit = {
    headers: {
      Authorization: "Bearer " + token,
    },
    method: method,
  };

  if (body !== "undefined") {
    options.body = body;
  }

  const response = await fetch(url, options);

  if (!response.ok) {
    const error = new Error(`HTTP error! status: ${response.status}`);
    (error as any).response = response;
    throw error;
  }

  return response;
}
