import React, {useEffect, useRef, useState} from "react";
import CanvasJSReact from '@canvasjs/react-charts';
import CBApi from "../../utils/datafeed/CBApi";
import {ReactTabulator} from "react-tabulator";
import {useFetchSmartbotLastTrades} from "../../hooks/useFetchSmartbot";

const CBElementSmartbotAccumulativeProfit = React.forwardRef(({Data, onMouseDown, onMouseUp, onTouchEnd}, ref) => {
  const [chartData, setChartData] = useState([])
  const chartRef = useRef(null);
  const containerRef = useRef(null);

  var CanvasJSChart = CanvasJSReact.CanvasJSChart;

  const options = {
    theme: "dark1",
    animationEnabled: true,
    exportEnabled: true,
    zoomEnabled: true,
    // height: 200,
    // title: {
    //   text: "Accumulative Profit",
    //
    // },
    axisX: {
      valueFormatString: "YYYY-MM-DD",
    },
    axisY: {
      title: "USD"
    },
    data: [
      {
        type: "area",
        xValueFormatString: "YYYY-MM-DD HH:mm:ss",
        yValueFormatString: "#,##0.## USD",
        dataPoints: chartData,
      }
    ]
  }

  useEffect(() => {
    const fetchData = async () => {
      if (!Data?.botData) {
        return
      }
      try {
        let Resource = `smartbot/${Data?.botData?._id}/accumulativeprofitusd`
        if (Data?.botData?.backtester_id) {
          Resource = `backtest/${Data.botData.backtester_id}/accumulativeprofitusd`
        }

        const response = CBApi.fetch(Resource);
        response.then((data) => {
          if (data?.status === 'ok') {
            data = data.data
            const chartData = data.map(item => ({x: new Date(item.exchange_lasttradedate), y: item.calcUSD}));
            setChartData(chartData)

          }
        })

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [Data?.botData?._id]);

  const containerProps = {
    height: "calc(100% - 30px)",
  }

  useEffect(() => {
    const handleResize = () => {
      if (chartRef.current) {
        chartRef.current.render();
      }
    };

    const resizeObserver = new ResizeObserver(handleResize);
    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        resizeObserver.unobserve(containerRef.current);
      }
    };
  }, []);

  return (
    <div ref={containerRef}>
      <div draggable="true" className="cb-element-draggable-header">Accumulative Profit</div>
      <div className="cb-element-content">
        <div className="cb-element-smartbot-accumulative">
          <CanvasJSChart options = {options} containerProps={containerProps} onRef={ref => chartRef.current = ref}/>
        </div>
      </div>
    </div>
  )
})

const CBElementSmartbotLastTrades = React.forwardRef(({Data, onMouseDown, onMouseUp, onTouchEnd}, ref) => {
  const SmartbotLastTradeTableColumns = [
      {
        title: 'id',
        field: '_id',
        visible: false,
      },
      {
        title: 'Symbol',
        field: 'smartbot_symbol',
        hozAlign: 'center',
        vertAlign: 'middle',
        headerFilter: 'input',
        headerFilterFunc: 'like',
      },
    {
      title: 'Name',
      field: 'smartbot_name',
      hozAlign: 'center',
      vertAlign: 'middle',
      headerFilter: 'input',
      headerFilterFunc: 'like',
    },
      {
        title: 'Amount',
        field: 'amount',
        hozAlign: 'right',
        vertAlign: 'middle',
        headerFilter: 'input',
        headerFilterFunc: 'like',
      },
      {
        title: 'Side',
        field: 'side',
        hozAlign: 'center',
        vertAlign: 'middle',
        headerFilter: 'input',
        headerFilterFunc: 'like',
      },
      {
        title: 'Date',
        field: 'exchange_lasttradedate',
        hozAlign: 'center',
        vertAlign: 'middle',
        headerFilter: 'input',
        headerFilterFunc: 'like',
        // formatter: 'datetime',
        // formatterParams: {
        //   inputFormat: 'YYYY-MM-DD HH:mm:ss',
        //   outputFormat: 'YYYY-MM-DD HH:mm:ss',
        // },
      },
    ]

    const [smartbotLastTrade] = useFetchSmartbotLastTrades()

    return (
      <div>
        <div draggable="true" className="cb-element-draggable-header">Smartbot last trades</div>
        <div className="cb-element-content">
          <div className="cb-element-smartbot-lasttrade">
            <ReactTabulator
              columns={SmartbotLastTradeTableColumns}
              data={smartbotLastTrade}
            />
          </div>
        </div>
      </div>
    )
  })

export {CBElementSmartbotAccumulativeProfit, CBElementSmartbotLastTrades}