import React, {useContext, useLayoutEffect} from 'react'
import { GlobalContext } from '../../../state'
import { useQuery } from 'react-query'
import 'react-tabulator/lib/styles.css' // required styles
import 'react-tabulator/lib/css/tabulator.min.css' // theme
import { ReactTabulator } from 'react-tabulator'
import tokenfromLS from '../../../utils/tokenFromLS'
import useFetcher from "../../../hooks/useFetcher";
import {useNavigate} from "react-router-dom";
import Fieldset from '../../EditFields/Fieldset/Fieldset'
import * as am5 from "@amcharts/amcharts5";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5percent from "@amcharts/amcharts5/percent";
import CBElements from "../../Containers/CBElements";

const DashboardPage = ({ mainContainerHeight }) => {
  // Global state
  const globalState = useContext(GlobalContext)
  // API Host Address
  const apiHost = process.env.REACT_APP_API_URI
  const navigate = useNavigate();

  const [TopProfitBotsData, setTopProfitBotsData] = useFetcher(
    `smartbot/stats/bestof`,
    'GET',
    // sanitizeTopProfitBots
  )

  // Resource URI
  const resource = `${apiHost}/stats/coinmarket/${globalState.coinSymbols.join(
    ','
  )}/id,name`
  // Fetch options
  const fetchOptions = {
    headers: {
      Authorization: `Bearer ${tokenfromLS(globalState.token)}`,
    },
  }
  // Fetch Fn for useQuery
  const fetchSymbols = () =>
    fetch(resource, fetchOptions).then((res) => { 
      // console.log(res.json)
      return res.json()
    })
  // useQuery call
  const { isError, isLoading, isSuccess, error, data } = useQuery(
    'dashboardCoins',
    fetchSymbols
  )

  const resource_stats_balance_current = `${process.env.REACT_APP_API_URI}/account/stats/balance_current`
  const fetchAccount_stats_balance_current = () =>
    fetch(resource_stats_balance_current, fetchOptions)
      .then((res) => res.json())
      .then((out) => {
        return out
      })

  const { data: data_stats_balance_current } = useQuery(
    'account_stats_balance_current',
    fetchAccount_stats_balance_current,
    // { refetchInterval: 60000 }
  )

  useLayoutEffect(() => {
    // if HTML Element chartdiv_stats_trade exists
    if (!document.getElementById('chartdiv_stats_balance_current')) {
      return
    }

    let root_stats_balance_current = am5.Root.new('chartdiv_stats_balance_current')

    root_stats_balance_current.setThemes([am5themes_Animated.new(root_stats_balance_current)])

    let chart = root_stats_balance_current.container.children.push(
      am5percent.PieChart.new(root_stats_balance_current, {
        endAngle: 270,
      })
    );

    let series = chart.series.push(am5percent.PieSeries.new(root_stats_balance_current, {
      valueField: "value",
      categoryField: "category",
      innerRadius: am5.percent(0),
      outerRadius: am5.percent(80),
      legendLabelText: "[{fill}]{category}[/]",
      legendValueText: "[bold]{valuePercentTotal.formatNumber('0.00')}%[/]",
      // legendValueText: "[bold {fill}]{value}[/]",
      alignLabels: false,
      tooltip: am5.Tooltip.new(root_stats_balance_current, {
        pointerOrientation: "vertical",
        labelText: "[bold]{category}[/]\nPercentage: [bold]{valuePercentTotal.formatNumber('0.00')}%[/]\nAmount: {valueBase}\nUSD: {calcUSD.formatNumber('$#.##')}",
        fontColor: "#000000",
        fontSize: 12,
        fontWeight: "bold"
      })
    }));

    series.labels.template.adapters.add("y", function(y, target) {
      var dataItem = target.dataItem;
      if (dataItem) {
        var tick = dataItem.get("tick");
        if (dataItem.get("valuePercentTotal") < 5) {
          target.set("forceHidden", true);
          tick.set("forceHidden", true);
        } else {
          target.set("forceHidden", false);
          tick.set("forceHidden", true);
        }
        return y;
      }
    });

    // if data_stats_balance_current.calcUSD = 0 then show "nothing"
    if (data_stats_balance_current && data_stats_balance_current.calcUSD && data_stats_balance_current.calcUSD !== 0.0) {
      // Move over all subaccounts:
      let SubAccounts = Object.keys(data_stats_balance_current.data)
      let data = []
      for (let i = 0; i < SubAccounts.length; i++) {
        // Lets move over all coins:
        let Coins = Object.keys(data_stats_balance_current.data[SubAccounts[i]])
        for (let j = 0; j < Coins.length; j++) {
          // Ignore all Coins without "calcUSD" in the name:
          if (Coins[j].indexOf('calcUSD') === -1) {
            continue
          }
          let CoinName = Coins[j].slice(0, -7)
          if (CoinName === '') {
            continue
          }
          let dataEntry = {
            // category name without "calcUSD":
            category: CoinName,
            value: data_stats_balance_current.data[SubAccounts[i]][Coins[j]],
            valueBase: data_stats_balance_current.data[SubAccounts[i]][CoinName],
            calcUSD: data_stats_balance_current.data[SubAccounts[i]][CoinName + 'calcUSD'],
          }
          data.push(dataEntry)
        }
      }

      // Sort data by calcUSD:
      data.sort(function(a, b) {
        return b.calcUSD - a.calcUSD;
      })
      // Filter data to show only > 0.01$
      data = data.filter(function (el) {
        return el.calcUSD > 0.02;
      })

      series.data.setAll(data)

    } else {
      series.data.setAll([
        {category: "Nothing", value: 100.0},
      ]);
    }

    // series.labels.template.set("visible", false);
    // series.ticks.template.set("visible", false);
    series.labels.template.setAll({
      radius: 60,
      inside: true,
    })

    // Legend
    // let legend = chart.children.push(am5.Legend.new(root_stats_balance_current, {
    //   // Right align legend to chart's center
    //   width: am5.p100,
    //   paddingBottom: 15,
    //   align: "right",
    //   orientation: "vertical",
    // }));
    // legend.data.setAll(series.dataItems);
    // console.log(series.dataItems)
    let legend = chart.children.push(am5.Legend.new(root_stats_balance_current, {

      }
    ));
    legend.data.setAll(series.dataItems);


    chart.appear(1000, 100)

    return () => {
      root_stats_balance_current.dispose()
    }
  }, [data_stats_balance_current, isSuccess])

  if (isLoading) {
    return <span>Loading...</span>
  }

  if (isError) {
    return <span>`An error has occurred: ${error.message}`</span>
  }



  // Create a table with the following columns:
  // - Symbol
  // - Bot Name
  // - User Alias
  // - Profit USD
  // - Profit %
  const TopProfitBotsColumns = [
    {
      title: 'Rank',
      field: 'rank',
      hozAlign: 'center',
      vertAlign: 'middle',
    },
    {
      title: 'Symbol',
      field: 'symbol',
      hozAlign: 'center',
      vertAlign: 'middle',
      cellClick: function (e, cell) {
        if (cell.getData().url !== false) {
          // redirect to url
          navigate(cell.getData().url)
        }
      }
    },
    { title: 'Bot Name', field: 'name', hozAlign: 'center', vertAlign: 'middle' },
    { title: 'User Alias', field: 'user_alias', hozAlign: 'center', vertAlign: 'middle' },
    {
      title: 'Profit USD',
      field: 'profitUSD',
      hozAlign: 'center',
      vertAlign: 'middle',
      formatter: 'money',
      formatterParams: {
        precision: 2,
      },
    },
    {
      title: 'Profit %',
      field: 'percent_profit',
      hozAlign: 'center',
      vertAlign: 'middle',
      formatter: 'money',
      formatterParams: {
        precision: 2,
      },
      sorter: 'number',
      sorterParams: {
        format: '0.00',
        alignEmptyValues: 'bottom',
      },
    },
    {
      title: 'Age',
      field: 'age',
      hozAlign: 'center',
      vertAlign: 'middle',
      formatter: 'money',
      formatterParams: {
        precision: 0,
      },
    }
  ]

  const Defaultlayout = {
    lg: [
      {"w":12,"h":3,"x":0,"y":0,"i":"account-balance","minW":4,"minH":1,"moved":false,"static":false},
      {"w":12,"h":3,"x":12,"y":0,"i":"account-profit","minW":4,"minH":1,"moved":false,"static":false},
      {"w":30,"h":2,"x":0,"y":1,"i":"smartbot-lasttrade","minW":4,"minH":1,"moved":false,"static":false},
    ],
    md: [
      {"w":10,"h":3,"x":0,"y":0,"i":"account-balance","minW":4,"minH":1,"moved":false,"static":false},
      {"w":10,"h":3,"x":12,"y":0,"i":"account-profit","minW":4,"minH":1,"moved":false,"static":false},
      {"w":20,"h":2,"x":0,"y":1,"i":"smartbot-lasttrade","minW":2,"minH":1,"moved":false,"static":false},
    ],
    sm: [
      {"w":6,"h":3,"x":0,"y":0,"i":"account-balance","minW":4,"minH":1,"moved":false,"static":false},
      {"w":6,"h":3,"x":6,"y":0,"i":"account-profit","minW":4,"minH":1,"moved":false,"static":false},
      {"w":12,"h":2,"x":0,"y":1,"i":"smartbot-lasttrade","minW":4,"minH":1,"moved":false,"static":false}
    ],
    xs: [
      {"w":4,"h":3,"x":0,"y":0,"i":"account-balance","minW":4,"minH":1,"moved":false,"static":false},
      {"w":4,"h":3,"x":4,"y":0,"i":"account-profit","minW":4,"minH":1,"moved":false,"static":false},
      {"w":8,"h":2,"x":0,"y":1,"i":"smartbot-lasttrade","minW":4,"minH":1,"moved":false,"static":false}
    ],
    xxs: [
      {"w":4,"h":3,"x":0,"y":0,"i":"account-balance","minW":4,"minH":1,"moved":false,"static":false},
      {"w":4,"h":3,"x":4,"y":0,"i":"account-profit","minW":4,"minH":1,"moved":false,"static":false},
      {"w":8,"h":2,"x":0,"y":1,"i":"smartbot-lasttrade","minW":4,"minH":1,"moved":false,"static":false}
    ],
  }


  return (
    <React.Fragment>
    <div>
      <CBElements GridLayoutParameter={[]} ElementNames={[
        'account-balance',
        'account-profit',
        'smartbot-lasttrade',
      ]} Prefix="dashboard" DefaultGridLayout={Defaultlayout} Data={{botData: null}} />
      {/*<Fieldset columns={1} dismiss={true}>*/}
      {/*  <CBElements GridLayoutParameter={layoutParameters} ElementNames={[*/}
      {/*    'account-balance',*/}
      {/*  ]} Prefix="dashboard" DefaultGridLayout={Defaultlayout} />*/}
      {/*</Fieldset>*/}
      {/*<Fieldset*/}
      {/*  legend="Account Balance"*/}
      {/*  columns={1}*/}
      {/*  dismiss={true}*/}
      {/*>*/}
      {/*  <div*/}
      {/*    id="chartdiv_stats_balance_current"*/}
      {/*    style={{width: '100%', height: '400px'}}*/}
      {/*  ></div>*/}

      {/*</Fieldset>*/}
      <Fieldset
        legend="Top Bots (last 30 days)"
        columns={1}
        dismiss={true}
      >
        <ReactTabulator
          options={{ height: mainContainerHeight }}
          columns={TopProfitBotsColumns}
          data={TopProfitBotsData?.data ?? []}
        />
      </Fieldset>
    </div>
    </React.Fragment>
  )
}

export default DashboardPage
