import React from 'react'

const state = {
  token: '',
  isMenuActive: false,
  activeMenuItem: '',
  rights: {},
  apikey: '',
  sidebarNavItems: [
    {
      checkRight: false,
      right: null,
      name: 'Dashboard',
      path: '/dashboard',
      icon: 'BiDetail',
    },
    {
      checkRight: false,
      right: null,
      name: 'Account',
      path: '/account',
      icon: 'BiUserCircle',
    },
    {
      checkRight: true,
      right: 'THREECMAXBOT',
      name: '3CMaxBot',
      path: '/3cmaxbot',
      icon: 'BiBot',
    },
    {
      checkRight: true,
      right: 'SMARTBOT',
      name: 'SmartBot',
      path: '/smartbot',
      icon: 'BiBrain',
    },
    {
      checkRight: true,
      right: 'GRIDBOT',
      name: 'GridBot',
      path: '/gridbot',
      icon: 'BiGrid',
    },
    {
      checkRight: true,
      right: 'BACKTEST',
      name: 'Backtest',
      path: '/backtest',
      icon: 'BiShoppingBag',
    },
    {
      checkRight: false,
      right: null,
      name: 'Signal',
      path: '/signal',
      icon: 'BiBroadcast',
    },
    {
      checkRight: true,
      right: 'SIGNALPROCESSOR',
      name: 'Signal Processor',
      path: '/signalprocessor',
      icon: 'GiProcessor',
    },
    {
      checkRight: true,
      right: 'DEV',
      name: 'Bot Setup',
      path: '/bot-setup',
      icon: 'BiCog',
    },
    {
      checkRight: true,
      right: 'DEV',
      name: 'Help / Guide',
      path: '/help',
      icon: 'BiBuoy',
    },
    {
      checkRight: false,
      right: null,
      name: 'Usefully Links',
      path: '/links',
      icon: 'BiLinkAlt',
    },
    {
      checkRight: false,
      right: null,
      name: 'User Settings',
      path: '/user',
      icon: 'BiUserCircle',
    },
  ],
  coinSymbols: [
    'btc',
    'eth',
    'bnb',
    'ada',
    'dot',
    'eos',
    'etc',
    'atom',
    'xrp',
    'algo',
    'comp',
    'mkr',
    'xlm',
    'doge',
    'vet',
    '1inch',
    'aave',
    'omg',
    'uni',
    'iost',
    'link',
    'ltc',
    'qtum',
    'theta',
    'zil',
    'band',
    'zrx',
    'cvc',
    'bat',
    'bch',
    'matic',
    'luna',
    'neo',
    'lrc',
    'ren',
    'grt',
    'xtz',
    'chz',
    'alpha',
    'ankr',
    'hnt',
    'coti',
    'ftm',
    'rune',
    'srm',
    'storj',
    'sxp',
    'yfi',
    'dodo',
    'hbar',
    'mana',
    'near',
    'one',
    'reef',
    'rvn',
    'sfp',
    'xem',
    'zen',
    'sol',
    'rlc',
    'tomo',
    'alice',
    'kncl',
    'trb',
    'miota',
    'btt',
    'dent',
    'fil',
    'hot',
    'sc',
  ],
}

const GlobalContext = React.createContext()

export { state, GlobalContext }
