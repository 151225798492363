import React, { useLayoutEffect, useContext, useState } from 'react'
import Fieldset from '../../EditFields/Fieldset/Fieldset'
import InputField from '../../EditFields/Input/InputField'
import Select from '../../EditFields/Select/Select'
import * as am5 from '@amcharts/amcharts5'
import * as am5xy from '@amcharts/amcharts5/xy'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'
import { GlobalContext } from '../../../state'
import tokenfromLS from '../../../utils/tokenFromLS'
import { useQuery } from 'react-query'
import {useNavigate, useParams} from 'react-router-dom'
import useFetcher from '../../../hooks/useFetcher'
import {
  sanitizeAccountData,
} from '../../../utils/sanatizer'

import Modal, {useModal} from '../../Modal/Modal'
import * as am5percent from "@amcharts/amcharts5/percent";

const AccountDetailPage = () => {
  const {openModal} = useModal();

  let params = useParams();
  let id = params.id

  // const [accountData, setAccountData] = useFetchAccount(id)
  const [accountData, setAccountData] = useFetcher(
    `account/${id}`,
    'GET',
    sanitizeAccountData
  )
  const [formSubmitMsg, setformSubmitMsg] = useState(null)

  const navigate = useNavigate()

  // Global state
  const globalState = useContext(GlobalContext)
  // Resource URI
  const resource_stats_trade = `${process.env.REACT_APP_API_URI}/account/${id}/stats/trade`
  const resource_stats_trade_year = `${process.env.REACT_APP_API_URI}/account/${id}/stats/trade/year`
  const resource_stats_balance = `${process.env.REACT_APP_API_URI}/account/${id}/stats/balance`
  const resource_stats_balance_current = `${process.env.REACT_APP_API_URI}/account/${id}/stats/balance_current`
  // Fetch options
  const fetchOptions = {
    headers: {
      Authorization: `Bearer ${tokenfromLS(globalState.token)}`,
    },
  }

  // Stats Trade USD Average array:
  const [statsTradeUSDAverage7, setStatsTradeUSDAverage7] = useState(0.0)
  const [statsTradeUSDAverage7_1, setStatsTradeUSDAverage7_1] = useState(0.0) // one day before
  const [statsTradeUSDAverage30, setStatsTradeUSDAverage30] = useState(0.0)
  const [statsTradeUSDAverage30_1, setStatsTradeUSDAverage30_1] = useState(0.0) // one day before

  // Fetch Fn for useQuery
  const fetchAccount_stats_trade = () =>
    fetch(resource_stats_trade, fetchOptions)
      .then((res) => res.json())
      .then((out) => {
        return out.map
          ? out.map((item) => ({
              date: new Date(item.date),
              value1: item.value1,
              value2: item.value2,
              value3: item.value3,
              value4: item.value4,
            }))
          : []
      })

  const fetchAccount_stats_trade_year = () =>
    fetch(resource_stats_trade_year, fetchOptions)
      .then((res) => res.json())
      .then((out) => {
        return out
      })

  // Fetch Fn for useQuery
  const fetchAccount_stats_balance = () =>
    fetch(resource_stats_balance, fetchOptions)
      .then((res) => res.json())
      .then((out) => {
        return out
      })

  const fetchAccount_stats_balance_current = () =>
    fetch(resource_stats_balance_current, fetchOptions)
      .then((res) => res.json())
      .then((out) => {
        return out
      })

  const { data: data_stats_trade } = useQuery(
    'account_stats_trade',
    fetchAccount_stats_trade,
    // {
    //   refetchInterval: 60000,
    // }
  )
  const { data: data_stats_trade_year } = useQuery(
    'account_stats_trade_year',
    fetchAccount_stats_trade_year,
  )

  const { data: data_stats_balance } = useQuery(
    'account_stats_balance',
    fetchAccount_stats_balance,
    // { refetchInterval: 60000 }
  )
  const { data: data_stats_balance_current } = useQuery(
    'account_stats_balance_current',
    fetchAccount_stats_balance_current,
    // { refetchInterval: 60000 }
  )

  useLayoutEffect(() => {
    // if HTML Element chartdiv_stats_trade exists
    if (!document.getElementById('chartdiv_stats_trade')) {
      return
    }
    let root_stats_trade = am5.Root.new('chartdiv_stats_trade')

    root_stats_trade.setThemes([am5themes_Animated.new(root_stats_trade)])

    let chart = root_stats_trade.container.children.push(
      am5xy.XYChart.new(root_stats_trade, {
        panX: true,
        panY: false,
        wheelX: 'panX',
        wheelY: 'zoomX',
        layout: root_stats_trade.verticalLayout,
      })
    )

    chart.set(
      'scrollbarX',
      am5.Scrollbar.new(root_stats_trade, {
        orientation: 'horizontal',
      })
    )

    // Create Y-axis
    let yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root_stats_trade, {
        renderer: am5xy.AxisRendererY.new(root_stats_trade, {}),
      })
    )

    // // Create Y-axis
    // let yAxisCount = chart.yAxes.push(
    //   am5xy.ValueAxis.new(root_stats_trade, {
    //     syncWithAxis: yAxis,
    //     renderer: am5xy.AxisRendererY.new(root_stats_trade, {opposite: true})
    //   })
    // );

    // Create X-Axis
    let xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root_stats_trade, {
        renderer: am5xy.AxisRendererX.new(root_stats_trade, {}),
        categoryField: 'date',
      })
    )

    // Create series
    let series1 = chart.series.push(
      am5xy.ColumnSeries.new(root_stats_trade, {
        name: 'Trade count',
        xAxis: xAxis,
        yAxis: yAxis,
        // yAxis: yAxisCount,
        valueYField: 'value1',
        categoryXField: 'date',
      })
    )

    let series2 = chart.series.push(
      am5xy.ColumnSeries.new(root_stats_trade, {
        name: 'USD trade',
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: 'value2',
        categoryXField: 'date',
        // visible: false,
        tooltip: am5.Tooltip.new(root_stats_trade, {
          pointerOrientation: 'horizontal',
          labelText: '{valueY}',
        }),
      })
    )

    let series3 = chart.series.push(
      am5xy.ColumnSeries.new(root_stats_trade, {
        name: 'Fee trade count',
        xAxis: xAxis,
        yAxis: yAxis,
        // yAxis: yAxisCount,
        valueYField: 'value3',
        categoryXField: 'date',
        // visible: false,
      })
    )

    let series4 = chart.series.push(
      am5xy.ColumnSeries.new(root_stats_trade, {
        name: 'Fee trade USD',
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: 'value4',
        categoryXField: 'date',
        // visible: false,
      })
    )
    if (data_stats_trade) {
      // console.log(data_stats_trade)
      xAxis.data.setAll(data_stats_trade)
      series1.data.setAll(data_stats_trade)
      series2.data.setAll(data_stats_trade)
      series3.data.setAll(data_stats_trade)
      series4.data.setAll(data_stats_trade)

      // Set last 7-day average for series 2 in a new variable:
      for (let i = 0; i < data_stats_trade.length; i++) {
        let statsTradeUSDAverage = 0
        let statsTradeUSDAverageCount = 0
        for (let j = 0; j < 7; j++) {
          if (data_stats_trade[i - j]) {
            statsTradeUSDAverage += data_stats_trade[i - j].value2
            statsTradeUSDAverageCount++
          }
        }
        setStatsTradeUSDAverage7(statsTradeUSDAverage / statsTradeUSDAverageCount)
      }

      // Set last 7-day average for series 2 in a new variable:
      for (let i = 0; i < data_stats_trade.length; i++) {
        let statsTradeUSDAverage = 0
        let statsTradeUSDAverageCount = 0
        for (let j = 1; j < 8; j++) {
          if (data_stats_trade[i - j]) {
            statsTradeUSDAverage += data_stats_trade[i - j].value2
            statsTradeUSDAverageCount++
          }
        }
        setStatsTradeUSDAverage7_1(statsTradeUSDAverage / statsTradeUSDAverageCount)
      }

      // Set last 30-day average for series 2 in a new variable:
      for (let i = 0; i < data_stats_trade.length; i++) {
        let statsTradeUSDAverage = 0
        let statsTradeUSDAverageCount = 0
        for (let j = 0; j < 30; j++) {
          if (data_stats_trade[i - j]) {
            statsTradeUSDAverage += data_stats_trade[i - j].value2
            statsTradeUSDAverageCount++
          }
        }
        setStatsTradeUSDAverage30(statsTradeUSDAverage / statsTradeUSDAverageCount)
      }

      // Set last 30-day average for series 2 in a new variable:
      for (let i = 0; i < data_stats_trade.length; i++) {
        let statsTradeUSDAverage = 0
        let statsTradeUSDAverageCount = 0
        for (let j = 1; j < 31; j++) {
          if (data_stats_trade[i - j]) {
            statsTradeUSDAverage += data_stats_trade[i - j].value2
            statsTradeUSDAverageCount++
          }
        }
        setStatsTradeUSDAverage30_1(statsTradeUSDAverage / statsTradeUSDAverageCount)
      }

    }

    // Add legend
    let legend = chart.children.push(am5.Legend.new(root_stats_trade, {}))
    legend.data.setAll(chart.series.values)

    // Add cursor
    chart.set('cursor', am5xy.XYCursor.new(root_stats_trade, {}))

    return () => {
      root_stats_trade.dispose()
    }
  }, [accountData, data_stats_trade])

  useLayoutEffect(() => {
    // if HTML Element chartdiv_stats_trade_year exists
    if (!document.getElementById('chartdiv_stats_trade_year')) {
      return
    }

    let root_stats_trade_year = am5.Root.new('chartdiv_stats_trade_year')

    root_stats_trade_year.setThemes([am5themes_Animated.new(root_stats_trade_year)])

    let chart = root_stats_trade_year.container.children.push(
      am5xy.XYChart.new(root_stats_trade_year, {
        panX: false,
        panY: false,
        paddingLeft: 0,
      })
    )

    // Create X-Axis
    let xAxis = chart.xAxes.push(am5xy.DateAxis.new(root_stats_trade_year, {
      maxDeviation: 0,
      baseInterval: {
        timeUnit: "year",
        count: 1
      },
      renderer: am5xy.AxisRendererX.new(root_stats_trade_year, {
        minorGridEnabled:true,
        minorLabelsEnabled:true
      }),
      tooltip: am5.Tooltip.new(root_stats_trade_year, {})
    }));

    xAxis.set("minorDateFormats", {
      "year":"yyyy"
    });

    let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root_stats_trade_year, {
      renderer: am5xy.AxisRendererY.new(root_stats_trade_year, {})
    }));

    // Add series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
    let series = chart.series.push(am5xy.ColumnSeries.new(root_stats_trade_year, {
      name: "Series",
      xAxis: xAxis,
      yAxis: yAxis,
      valueYField: "total",
      valueXField: "_id",
      tooltip: am5.Tooltip.new(root_stats_trade_year, {
        labelText: "{valueY}"
      })
    }));

    let data = []
    if (data_stats_trade_year && data_stats_trade_year.data && data_stats_trade_year.data.length) {
      for (let i = 0; i < data_stats_trade_year.data.length; i++) {
        let dataEntry = {
          _id: new Date(data_stats_trade_year.data[i]._id + "-01-01").getTime(),
          total: data_stats_trade_year.data[i].total,
        }
        data.push(dataEntry)
      }
    }
    series.data.setAll(data)

    // Add cursor
    chart.set('cursor', am5xy.XYCursor.new(root_stats_trade_year, {}))

    return () => {
      root_stats_trade_year.dispose()
    }
  }, [accountData, data_stats_trade_year])

  useLayoutEffect(() => {
    // if HTML Element chartdiv_stats_trade exists
    if (!document.getElementById('chartdiv_stats_balance')) {
      return
    }

    let root_stats_balance = am5.Root.new('chartdiv_stats_balance')

    root_stats_balance.setThemes([am5themes_Animated.new(root_stats_balance)])

    let chart = root_stats_balance.container.children.push(
      am5xy.XYChart.new(root_stats_balance, {
        panX: true,
        panY: false,
        wheelX: 'panX',
        wheelY: 'zoomX',
        // maxTooltipDistance: 0,
        pinchZoomX: true,
      })
    )

    let date = new Date()
    date.setHours(0, 0, 0, 0)

    let xAxis = chart.xAxes.push(
      am5xy.DateAxis.new(root_stats_balance, {
        // maxDeviation: 0.2,
        baseInterval: {
          // timeUnit: "day",
          timeUnit: 'minute',
          count: 5,
        },
        renderer: am5xy.AxisRendererX.new(root_stats_balance, {}),
        tooltip: am5.Tooltip.new(root_stats_balance, {
          // pointerOrientation: 'horizontal',
          labelText: "{name}, {categoryX}: {valueX} {valueY} {value}",
        }),
      })
    )

    // Create Y-axis
    let yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root_stats_balance, {
        renderer: am5xy.AxisRendererY.new(root_stats_balance, {}),
      })
    )

    // Add series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
    if (data_stats_balance && data_stats_balance.account) {
      for (var i = 0; i < data_stats_balance.account.length; i++) {
        // for (var i = 0; i < 3; i++) {
        let series = chart.series.push(
          am5xy.LineSeries.new(root_stats_balance, {
            name: 'Account ' + data_stats_balance.account[i],
            //+ data_stats_balance.indexOf(i),
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: 'value',
            valueXField: 'date',
            legendValueText: '{valueY}',
            visible: i === 0,
            // tooltip: am5.Tooltip.new(root_stats_balance, {
            //   pointerOrientation: 'horizontal',
            //   labelText: '{valueY}',
            // }),
          })
        )

        // date = new Date();
        // date.setHours(0, 0, 0, 0);
        // value = 0;

        // let data = generateDatas(100);
        // console.log(data)
        let data = []

        // console.log(Object.keys(data_stats_balance))
        for (let j = 0; j < Object.keys(data_stats_balance).length; ++j) {
          let entry = Object.keys(data_stats_balance)[j]
          if (entry !== 'account') {
            if (data_stats_balance[j][data_stats_balance.account[i]]) {
              let dataEntry = {
                date: new Date(data_stats_balance[j].date).getTime(),
                value: parseFloat(data_stats_balance[j][data_stats_balance.account[i]]),
              }
              // console.log(data_stats_balance[j].date)
              data.push(dataEntry)
              // console.log(dataEntry)
              // am5.time.add(date, "day", 1);
              // console.log(data_stats_balance[j])
            }
          }
          //   // console.log(data_stats_balance[j])
          //   // data.push(generateData());
        }

        series.fills.template.setAll({
          fillOpacity: 0.2,
          visible: true,
        })

        // console.log(data)
        series.data.setAll(data)
        // series.hide()

        // Make stuff animate on load
        // https://www.amcharts.com/docs/v5/concepts/animations/
        // series.appear()
      }
    }

    // Add cursor
    // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
    let cursor = chart.set(
      'cursor',
      am5xy.XYCursor.new(root_stats_balance, {
        behavior: 'none',
      })
    )
    cursor.lineY.set('visible', false)

    // Add scrollbar
    // https://www.amcharts.com/docs/v5/charts/xy-chart/scrollbars/
    chart.set(
      'scrollbarX',
      am5.Scrollbar.new(root_stats_balance, {
        orientation: 'horizontal',
      })
    )

    chart.set(
      'scrollbarY',
      am5.Scrollbar.new(root_stats_balance, {
        orientation: 'vertical',
      })
    )

    // Add legend
    // https://www.amcharts.com/docs/v5/charts/xy-chart/legend-xy-series/
    let legend = chart.rightAxesContainer.children.push(
      am5.Legend.new(root_stats_balance, {
        width: 200,
        paddingLeft: 15,
        height: am5.percent(100),
      })
    )

    // When legend item container is hovered, dim all the series except the hovered one
    legend.itemContainers.template.events.on('pointerover', function (e) {
      let itemContainer = e.target

      // As series list is data of a legend, dataContext is series
      let series = itemContainer.dataItem.dataContext

      chart.series.each(function (chartSeries) {
        if (chartSeries !== series) {
          chartSeries.strokes.template.setAll({
            strokeOpacity: 0.15,
            stroke: am5.color(0x000000),
          })
        } else {
          chartSeries.strokes.template.setAll({
            strokeWidth: 3,
          })
        }
      })
    })

    // When legend item container is unhovered, make all series as they are
    legend.itemContainers.template.events.on('pointerout', function (e) {
      // let itemContainer = e.target
      // let series = itemContainer.dataItem.dataContext

      chart.series.each(function (chartSeries) {
        chartSeries.strokes.template.setAll({
          strokeOpacity: 1,
          strokeWidth: 1,
          stroke: chartSeries.get('fill'),
        })
      })
    })

    legend.itemContainers.template.set('width', am5.p100)
    legend.valueLabels.template.setAll({
      width: am5.p100,
      textAlign: 'right',
    })

    // It's is important to set legend data after all the events are set on template, otherwise events won't be copied
    legend.data.setAll(chart.series.values)

    // Make stuff animate on load
    // https://www.amcharts.com/docs/v5/concepts/animations/
    chart.appear(1000, 100)

    return () => {
      root_stats_balance.dispose()
    }
  }, [accountData, data_stats_balance])

  useLayoutEffect(() => {
    // if HTML Element chartdiv_stats_trade exists
    if (!document.getElementById('chartdiv_stats_balance_current')) {
      return
    }

    let root_stats_balance_current = am5.Root.new('chartdiv_stats_balance_current')

    root_stats_balance_current.setThemes([am5themes_Animated.new(root_stats_balance_current)])

    let chart = root_stats_balance_current.container.children.push(
      am5percent.PieChart.new(root_stats_balance_current, {
        endAngle: 270,
      })
    );

    let series = chart.series.push(am5percent.PieSeries.new(root_stats_balance_current, {
      valueField: "value",
      categoryField: "category",
      innerRadius: am5.percent(0),
      outerRadius: am5.percent(80),
      legendLabelText: "[{fill}]{category}[/]",
      legendValueText: "[bold]{valuePercentTotal.formatNumber('0.00')}%[/]",
      // legendValueText: "[bold {fill}]{value}[/]",
      alignLabels: false,
      tooltip: am5.Tooltip.new(root_stats_balance_current, {
        pointerOrientation: "vertical",
        labelText: "[bold]{category}[/]\nPercentage: [bold]{valuePercentTotal.formatNumber('0.00')}%[/]\nAmount: {valueBase}\nUSD: {calcUSD.formatNumber('$#.##')}",
        fontColor: "#000000",
        fontSize: 12,
        fontWeight: "bold"
      })
    }));

    series.labels.template.adapters.add("y", function(y, target) {
      var dataItem = target.dataItem;
      if (dataItem) {
        var tick = dataItem.get("tick");
        if (dataItem.get("valuePercentTotal") < 5) {
          target.set("forceHidden", true);
          tick.set("forceHidden", true);
        } else {
          target.set("forceHidden", false);
          tick.set("forceHidden", true);
        }
        return y;
      }
    });

    // if data_stats_balance_current.calcUSD = 0 then show "nothing"
    if (data_stats_balance_current && data_stats_balance_current.calcUSD && data_stats_balance_current.calcUSD !== 0.0) {
      // Move over all subaccounts:
      let SubAccounts = Object.keys(data_stats_balance_current.data)
      let data = []
      for (let i = 0; i < SubAccounts.length; i++) {
        // Lets move over all coins:
        let Coins = Object.keys(data_stats_balance_current.data[SubAccounts[i]])
        for (let j = 0; j < Coins.length; j++) {
          // Ignore all Coins without "calcUSD" in the name:
          if (Coins[j].indexOf('calcUSD') === -1) {
            continue
          }
          let CoinName = Coins[j].slice(0, -7)
          if (CoinName === '') {
            continue
          }
          let dataEntry = {
            // category name without "calcUSD":
            category: CoinName,
            value: data_stats_balance_current.data[SubAccounts[i]][Coins[j]],
            valueBase: data_stats_balance_current.data[SubAccounts[i]][CoinName],
            calcUSD: data_stats_balance_current.data[SubAccounts[i]][CoinName + 'calcUSD'],
          }
          data.push(dataEntry)
        }
      }

      // Sort data by calcUSD:
      data.sort(function(a, b) {
        return b.calcUSD - a.calcUSD;
      })
      // Filter data to show only > 0.01$
      data = data.filter(function (el) {
        return el.calcUSD > 0.01;
      })

      series.data.setAll(data)

    } else {
      series.data.setAll([
        {category: "Nothing", value: 100.0},
      ]);
    }

    // series.labels.template.set("visible", false);
    // series.ticks.template.set("visible", false);
    series.labels.template.setAll({
      radius: 60,
      inside: true,
    })

    // Legend
    // let legend = chart.children.push(am5.Legend.new(root_stats_balance_current, {
    //   // Right align legend to chart's center
    //   width: am5.p100,
    //   paddingBottom: 15,
    //   align: "right",
    //   orientation: "vertical",
    // }));
    // legend.data.setAll(series.dataItems);
    // console.log(series.dataItems)
    let legend = chart.children.push(am5.Legend.new(root_stats_balance_current, {

      }
    ));
    legend.data.setAll(series.dataItems);


    chart.appear(1000, 100)

    return () => {
      root_stats_balance_current.dispose()
    }
  }, [accountData, data_stats_balance_current])

  const onSubmitForm = async (evt) => {
    evt.preventDefault()
    setformSubmitMsg(null)
    const formData = new FormData(evt.target)

    let data = Object.fromEntries(formData)
    const { secretKey, apiKey, secretToken, api, id, external_ips } = data
    delete data.secretKey
    delete data.apiKey
    delete data.secretToken
    delete data.apihidden
    // delete data.id
    data = {
      ...data,
      api: api,
      secret: {
        secretKey,
        apiKey,
        secretToken,
      },
      external_ips: accountData.external_ips ? accountData.external_ips : external_ips,
    }

    try {
      let RequestURL = `${process.env.REACT_APP_API_URI}/account/${id}`
      let Method = 'PUT'
      if (id === 'add') {
        RequestURL = `${process.env.REACT_APP_API_URI}/account`
        Method = 'ADD'
        delete data.id
      }
      const response = await fetch(RequestURL, {
        method: Method,
        body: JSON.stringify(data),
        headers: {
          Authorization: `Bearer ${tokenfromLS(globalState.token)}`,
          Accept: 'application/json',
          'Content-Type': 'application/json; charset=utf-8',
        },
      })

      const json = await response.json()
      if (json.status === 'ok') {
        navigate('/account');
      } else {
        openModal({
          content: 'Error: ' + (json.error || ' ') + (JSON.stringify(json?.data) || ' ') + (JSON.stringify(json?.error_description) || ' ' + JSON.stringify(json?.message) || ' '),
          showDismissButton: true,
          dismissButtonTxt: 'Close',
          showFooter: true,
        });
      }

    } catch (err) {
      openModal({
        content: 'Error: ' + err,
        showDismissButton: true,
        dismissButtonTxt: 'Close',
        showFooter: true,
      });
    }
  }

  // MODAL EXAMPLE
  const [showModal, setShowModal] = useState(false)
  const [showModal2, setShowModal2] = useState(false)
  const [showModalLoader, setShowModalLoader] = useState(false)

  const dismissModal = () => {
    setShowModal(false)
  }

  const dismissModal2 = () => {
    setShowModal2(false)
  }

  const submitModal = () => {
    setShowModalLoader(true)
    setTimeout(() => {
      setShowModalLoader(false)
      setShowModal(false)
      setShowModal2(true)
    }, 3000)
  }

  return (
    accountData && (
      <React.Fragment>
        {showModal ? (
          <Modal
            dismissFn={dismissModal}
            submitFn={submitModal}
            showFooter={true}
            showLoader={showModalLoader}
          >
            <p>
              <b>Name</b>: {accountData.name}
            </p>
            <p>
              <b>Type</b>: {accountData.type}
            </p>
            <p>
              <b>API</b>: {accountData.api}
            </p>
          </Modal>
        ) : null}

        {showModal2 ? (
          <Modal dismissFn={dismissModal2} showFooter={false}>
            <p>Bla blub</p>
          </Modal>
        ) : null}

        <div>
          <form
            style={{ padding: '0 4rem', margin: '0 auto' }}
            onSubmit={onSubmitForm}
          >
            <Fieldset
              legend="Balance"
              columns={1}
              collapsed={true}
              dismiss={true}
            >
              <div
                id="chartdiv_stats_balance"
                style={{width: '100%', height: '500px'}}
              ></div>
              Current Balance: {data_stats_balance_current?.calcUSD?.toFixed(2)} USD
              Total Bot Profit: {accountData?.profit_total_calcUSD?.toFixed(2)} USD
              <div
                id="chartdiv_stats_balance_current"
                style={{width: '100%', height: '400px'}}
              ></div>
            </Fieldset>
            <Fieldset
              legend="Trades"
              columns={1}
              collapsed={true}
              dismiss={true}
            >
              <div
                id="chartdiv_stats_trade"
                style={{ width: '100%', height: '500px' }}
              ></div>
              <div>
                Trade USD avg 7 days: {statsTradeUSDAverage7?.toFixed(2)} ({statsTradeUSDAverage7_1?.toFixed(2)})
              </div>
              <div>
                Trade USD avg 30 days: {statsTradeUSDAverage30?.toFixed(2)} ({statsTradeUSDAverage30_1?.toFixed(2)})
              </div>
              <div
                id="chartdiv_stats_trade_year"
                style={{ width: '100%', height: '500px' }}
              ></div>
            </Fieldset>
            <Fieldset legend="Account Settings" columns={2} dismiss={true}>
              {formSubmitMsg ? (
                <div>{JSON.stringify(formSubmitMsg)}</div>
              ) : null}
              {accountData.disabled_last_reason ? (
                <div>
                  <b>Disabled Reason</b>: {accountData.disabled_last_reason}
                </div>
              ) : null}
              <InputField
                gridcolumns="2"
                type="text"
                name="name"
                label="Account Name"
                value={accountData.name}
                pattern={'alphanumericwithspace'}
                onChange={function (evt) {
                  setAccountData(function (prevState) {
                    return {
                      ...prevState,
                      name: evt.target.value,
                    }
                  })
                }}
              />
              <Select
                name="type"
                label="Exchange Type"
                value={accountData.type}
                options={[
                  { name: 'BinanceFuture', value: 'BinanceFuture' },
                  { name: 'BinanceSpot', value: 'BinanceSpot' },
                  { name: 'BybitFuture', value: 'BybitFuture' },
                  { name: 'BybitSpot', value: 'BybitSpot' },
                  { name: 'KuCoinFuture (Final BETA Test)', value: 'KuCoinFuture' },
                  { name: 'KuCoinSpot (PRE ALPHA Test)', value: 'KuCoinSpot' },
                  { name: 'MexcSpot (PRE ALPHA Test)', value: 'MexcSpot' },
                  { name: '3commas (DEPRECATED)', value: '3commas' },
                ]}
                tooltip="<h3>Select the exchange type</h3><br><b>3commas</b> is only for 3commas account<br><b>BinanceFuture</b> is for Binance Futures<br><b>BinanceSpot</b> is for Binance Spot<br><b>BybitFuture</b> is for Bybit Futures<br><b>BybitSpot</b> is for Bybit Spot<br><b>KuCoinSpot</b> is for KuCoin Spot (TEST ONLY)<br><br><u>FTX Spot and Future is disabled for now!</u>"
                onChange={function (evt) {
                  const options = evt.target.options
                  const index = options.selectedIndex
                  let apiName = ''
                  console.log(options[index].value)

                  switch (options[index].value) {
                    case '3commas':
                      apiName = '3commas'
                      break
                    case 'BinanceFuture':
                      apiName = 'Binance'
                      break
                    case 'BinanceSpot':
                      apiName = 'Binance'
                      break
                    case 'BybitFuture':
                      apiName = 'Bybit'
                      break
                    case 'BybitSpot':
                      apiName = 'Bybit'
                      break
                    case 'KuCoinSpot':
                      apiName = 'KuCoin'
                      break
                    case 'KuCoinFuture':
                      apiName = 'KuCoinFutures'
                      break
                    case 'MexcSpot':
                      apiName = 'Mexc'
                      break
                    default:
                      apiName = '3commas'
                      break
                  }
                  setAccountData((prevState) => ({
                    ...prevState,
                    api: apiName,
                  }))
                }}
              />
              <InputField
                type="text"
                name="apiinput"
                label="Exchange API"
                value={accountData.api ? accountData.api : '3commas'}
                disabled={true}
              />
              <InputField
                type="hidden"
                name="api"
                label="Exchange API"
                value={accountData.api ? accountData.api : '3commas'}
              />
              <InputField
                type="text"
                name="apiKey"
                label="Exchange Key"
                value={accountData.secret && accountData.secret.apiKey}
                // pattern={'alphanumericwithspecialchars'}
                onChange={function (evt) {
                  setAccountData(function (prevState) {
                    return {
                      ...prevState,
                      secret: {
                        ...prevState.secret,
                        apiKey: evt.target.value,
                      },
                    }
                  })
                }}
              />
              <InputField
                type="text"
                name="secretKey"
                label="Secret Key"
                value={accountData.secret && accountData.secret.secretKey}
                // pattern={'alphanumericwithspecialchar'}
                onChange={function (evt) {
                  setAccountData(function (prevState) {
                    return {
                      ...prevState,
                      secret: {
                        ...prevState.secret,
                        secretKey: evt.target.value,
                      },
                    }
                  })
                }}
              />
              <InputField
                type="text"
                name="passphrase"
                label="Passphrase"
                value={accountData.secret && accountData.secret.passphrase}
                // pattern={'alphanumericwithspecialchar'}
                onChange={function (evt) {
                  setAccountData(function (prevState) {
                    return {
                      ...prevState,
                      secret: {
                        ...prevState.secret,
                        passphrase: evt.target.value,
                      },
                    }
                  })
                }}
                // Only enable for kucoin and kucoinfutures
                disabled={accountData?.api?.toLowerCase() !== 'kucoin' && accountData?.api?.toLowerCase() !== 'kucoinfutures'}
              />
              <InputField
                type="text"
                name="subaccount"
                label="SubAccount Name"
                value={accountData.subaccount}
                // pattern={'alphanumericwithspecialchar'}
                disabled={
                  accountData.api === '3commas' || accountData.api === 'Binance' || accountData.api === 'Bybit'
                }
                tooltip="KuCoin is the only exchange that support subaccount with one API key<br>Leave it blank if you don't use subaccount"
                onChange={function (evt) {
                  setAccountData(function (prevState) {
                    return {
                      ...prevState,
                      subaccount: evt.target.value,
                    }
                  })
                }}
              />
              <InputField
                type="text"
                name="secretToken"
                label="3commas API Secret Token (optional)"
                value={accountData.secret && accountData.secret.secretToken}
                // pattern={'alphanumericwithspecialchar'}
                onChange={function (evt) {
                  setAccountData(function (prevState) {
                    return {
                      ...prevState,
                      secret: {
                        ...prevState.secret,
                        secretToken: evt.target.value,
                      },
                    }
                  })
                }}
                disabled={accountData.api !== '3commas'}
              />
              <InputField
                type="switch"
                name="sandbox"
                label="Sandbox Mode"
                checked={accountData.sandbox}
                disabled={
                  accountData.api === 'KuCoin' || accountData.api === 'Binance'
                }
              />
              <InputField
                type="multiselect"
                name="external_ips"
                label="External IPs"
                value={accountData.external_ips}
                optionsvalue={accountData.external_ips_options}
                onChange={function (evt) {
                  // generate a comma separated list of IPs from the array:
                  let ips = evt
                  let ipsString = ''
                  for (let i = 0; i < ips.length; i++) {
                    if (ipsString !== '') {
                      ipsString += ','
                    }
                    ipsString += ips[i].value
                  }
                  setAccountData(function (prevState) {
                    return {
                      ...prevState,
                      external_ips: ipsString,
                    }
                  })
                }}
              />

              <InputField type="hidden" name="id" value={id} />
            </Fieldset>

            <button type="submit">Save</button>
            {/*<button*/}
            {/*  style={{ float: 'right' }}*/}
            {/*  onClick={(e) => {*/}
            {/*    e.preventDefault()*/}
            {/*    setShowModal(true)*/}
            {/*  }}*/}
            {/*>*/}
            {/*  Show Modal Example*/}
            {/*</button>*/}
          </form>
        </div>
      </React.Fragment>
    )
  )
}

export default AccountDetailPage
